import { AuthRoute } from '@/components/AuthRoute'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

const Login = lazy(() => import('@/pages/Login'))
const MainLayout = lazy(() => import('@/pages/MainLayout'))
const Dashboard = lazy(() => import('@/pages/Dashboard'))
const Batch = lazy(() => import('@/pages/Batch'))
const BatchPublish = lazy(() => import('@/pages/Batch/Publish'))
const BatchStockImport = lazy(() => import('@/pages/BatchStock/Import'))
const BatchDetail = lazy(() => import('@/pages/Batch/Detail'))
const Position = lazy(() => import('@/pages/Position'))
const Note = lazy(() => import('@/pages/Note'))

const router = createBrowserRouter([
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '/',
    element: (
      <AuthRoute>
        <MainLayout />
      </AuthRoute>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'batch',
        element: <Batch />,
      },
      {
        path: 'batch/publish',
        element: <BatchPublish />,
      },
      {
        path: 'batch/publish/:id',
        element: <BatchPublish />,
      },
      {
        path: 'batch/detail/:id',
        element: <BatchDetail />,
      },
      {
        path: 'batchstock/import/:id',
        element: <BatchStockImport />,
      },
      {
        path: 'position',
        element: <Position />,
      },
      {
        path: 'note',
        element: <Note />,
      },
    ],
  },
])

const Routerx = router

export default Routerx
